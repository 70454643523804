<template>
  <div id="Comfirm" v-if="show">
    <div class="dlg-dialog" id="dlg-dialog">
      <div class="dlg-head" @mousedown="onHeadMouseDown">
        {{ title }}
      </div>
      <div class="dlg-body">
        <div class="dlg-note">{{ text }}</div>
      </div>
      <div class="dlg-bom">
        <div class="dlg-left-btn" @click="onCancelClick">取消</div>
        <div class="dlg-right-btn" @click="onOkClick">确定</div>
      </div>
    </div>
  </div>
</template>

<script>
let self = null;
export default {
  name: "Comfirm",
  // model: {

  // },
  data: () => ({
    show: false,
    title: "提示",
    text: "消息",
    // success: null, //成功的方法
  }),
  created() {
    self = this;
  },
  methods: {
    onHeadMouseDown(ev) {
      var Drag = document.getElementById("dlg-dialog");
      // ev.stopPropagation();
      var disX = ev.clientX - Drag.offsetLeft;
      var disY = ev.clientY - Drag.offsetTop;
      document.onmousemove = function (event) {
        var ev = event || window.event;
        Drag.style.left = ev.clientX - disX + "px";
        Drag.style.top = ev.clientY - disY + "px";
        Drag.style.cursor = "move";
      };

      Drag.onmouseup = function () {
        document.onmousemove = null;
        this.style.cursor = "default";
      };
    },
    onOkClick(e) {
      this.closeDlg();
      if (this.success != null) {
        this.success(true);
      }
      // this.$emit('success', true);
    },
    onCancelClick(e) {
      this.closeDlg();
      // this.$emit('success', false);
      if (this.success != null) {
        this.success(false);
      }
    },
    closeDlg() {
      this.show = false;
      this.title = "提示";
      this.text = "消息";
    },
  },
};
</script>

<style scoped>
#Comfirm {
  /* //这个作为遮罩 */
  position: absolute;
  z-index: 999;
  top: 50px;
  left: 0px;
  width: 100vw;
  height: calc(100vh - 50px);
}
.dlg-dialog {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 25%;
  left: 50%;
  right: 50%;
  width: 310px;
  max-width: 460px;
  height: 160px;
  max-height: 600px;
  transform: translateX(-50%) translateY(-50%);
  color: #1f5081;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 10px;
  /* -webkit-app-region: drag; */
}
.dlg-head {
  height: 40px;
  text-align: center;
  line-height: 40px;
  padding-left: 10px;
  font-size: 1.4em;
  background-color: #ebebeb;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 1px 0px $bg-dark;
  user-select: none;
  /* // -webkit-app-region: drag; */
}

.dlg-body .dlg-note {
  padding: 10px;
  text-align: left;
  font-size: 1.2em;
}

.dlg-body {
  flex: 1 1 auto;
}

.dlg-bom {
  height: 40px;
  display: flex;
  /* // border: 1px solid red; */
  display: flex;
  font-size: 1.3em;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  user-select: none;
  border-top: 1px solid #1f5081;
}
.dlg-left-btn {
  flex: 1 1 auto;
  background-color: #fff;
  border-radius: 0 0 0 10px;
}

.dlg-left-btn:hover {
  background-color: #ebebeb;
}

.dlg-right-btn {
  flex: 1 1 auto;
  background-color: #1f5081;
  border-radius: 0 0 10px 0;
}
.dlg-right-btn:hover {
  background-color: #f60;
}
</style>