import { render, staticRenderFns } from "./IconBtn.vue?vue&type=template&id=1c63d0b2&scoped=true&"
import script from "./IconBtn.vue?vue&type=script&lang=js&"
export * from "./IconBtn.vue?vue&type=script&lang=js&"
import style0 from "./IconBtn.vue?vue&type=style&index=0&id=1c63d0b2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c63d0b2",
  null
  
)

export default component.exports