/**
 * CONNECTING：值为0，表示正在连接；
OPEN：值为1，表示连接成功，可以通信了；
CLOSING：值为2，表示连接正在关闭；
CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
 */
class Client {
  tryCount = 10
  openFlag = false
  myInterval = false
  path = ''
  ws = null

  constructor() {}

  on(eventName, callback) {
    this[eventName] = this[eventName] || new Array();
    for(var i=0; i<this[eventName].length; i++) { //避免重复发送
      if (this[eventName][i] == callback) {
        return;
      }
    }
    this[eventName].push(callback);
  }
  emit(eventName) {
    var params = arguments.length>1 ? Array.prototype.slice.call(arguments,1) :[];
    if(this[eventName]){
        Array.prototype.forEach.call(this[eventName],function(arg){
            arg.apply(this,params);
        });
    }
  }

  stat() {
    return this.ws.readyState
  }

  send(cmd, data) {
    var dat = {
      cmd: cmd,
      data: data
    }
    const str = JSON.stringify(dat)
    this.ws.send(str);
    this.heartBit();
    // console.log(str)
  }

  //init函数可在页面加载的时候就进行初始化或者根据自己的业务需求在需要打开通讯的时候在进行初始化
  init(path) {
    this.path = path
    // 实例化socket，这里的实例化直接赋值给this.ws是为了后面可以在其它的函数中也能调用websocket方法，例如：this.ws.close(); 完成通信后关闭WebSocket连接
    this.ws = new WebSocket(this.path)

    //监听是否连接成功
    this.ws.onopen = () => {
      this.openFlag = true
      console.log('连接成功，ws连接状态：' + this.ws.readyState);
      this.emit('opened', true);
      this.heartBit();
      //连接成功则发送一个数据
      // this.ws.send('连接成功');
    }

    //接听服务器发回的信息并处理展示
    this.ws.onmessage = (msg) => {
      this.heartBit();
      var str = msg.data;
      var objMsg = JSON.parse(str)
      if (objMsg.cmd == 'pong') {

      } else {
        this.emit('message', objMsg)
      }
      // console.log('接收到来自服务器的消息：');
      // console.log(objMsg)
    }

    //监听连接关闭事件
    this.ws.onclose = () => {
      this.openFlag = false
      this.emit('closed')
      //监听整个过程中websocket的状态
      console.log('连接关闭，ws连接状态：' + this.ws.readyState);
      this.myInterval = setInterval(() => {
        if (!this.openFlag && this.ws.readyState != 0) {
          this.init(this.path);
          this.tryCount ++
          if (this.tryCount > 3) {
            clearInterval(this.myInterval)
          }
        } else {
          clearInterval(this.myInterval)
        }
      }, 1000);
    }

    //监听并处理error事件
    this.ws.onerror = function (error) {
      this.emit('error', error)
      console.log(error);
    }
  }
  heartTimeOut = null
  heartBit() {
    var that = this;
    clearInterval(this.heartTimeOut)
    this.heartTimeOut = setInterval(function(){
      if (that.stat() == 1) {
        that.send('ping', {})
      } else {
        clearInterval(that.heartTimeOut)
      }
    }, 55*1000)
  }
}

const client = new Client()

export default {
  client
}
