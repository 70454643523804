<template>
  <div @click="onClick" id="IconBtn"
    :class="'IconBtn IconBtn_border_'+border+' IconBtn_' + size"
    v-ripple>
    <el-tooltip v-if="tip"
      effect="dark"
      :content="tip"
      :open-delay="600"
      placement="bottom">
      <i :class="'iconfont ' + icon + ' Icon_' + type + ' icon_' + size"></i>
    </el-tooltip>
    <i v-else :class="'iconfont ' + icon + ' Icon_' + type + ' icon_' + size"></i>
  </div>
</template>

<script>
export default {
  name: "IconBtn",
  data: () => ({
    // showLoading: false,
  }),
  model:{
    prop: 'loading',
    event: 'change'
  },
  props: {
    icon: {
      type: String,
      default: () => {
        return "iconhome";
      }
    },
    tip: {
      type: String,
      default: ()=>{
        return '';
      }
    },
    size: {
      type: String,
      default: ()=>{
        return 'medium'
      },
      validator: function (value) {
        if (value) {
          // 这个值必须匹配下列字符串中的一个
          return ['large', 'medium', 'small', 'mini'].indexOf(value) !== -1
        } else {
          return true;
        }
      }
    },
    type: {
      type: String,
      default: ()=>{
        return 'info';
      }
    },
    border: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    },
    loading: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    }
  },
  watch:{
    loading: function(val, old){
      // this.showLoading = val;
      console.log('showLoading: ' + val);
    }
  },
  methods:{
    onClick(e) {
      e.tip = this.tip;
      e.icon = this.icon;
      this.$emit('click',e);
    }
  }
};
</script>

<style scoped lang="scss">
.IconBtn {
  display: inline-block;
  text-align: center;
  // height: 26px;
  // width: 26px;
  // line-height: 26px;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  // border:1px solid transparent;

  border-radius: 5px;
}

.IconBtn_border_true {
   border: $border;
}

.Icon_text {
  color: #000;
}
.Icon_error {
  color: $error;
}
.Icon_warning {
  color: $warning;
}

.Icon_success {
  color: $success;
}

.IconBtn_large{
  display: inline-block;
  width: 40;
  height: 40;
  padding: 2px 10px;
  line-height: 36px;
}
.IconBtn_medium{
  width: 32;
  height: 32;
  padding: 1px 8px;
  line-height: 28px;
}
.IconBtn_small {
  width: 26px;
  height: 26px;
  line-height: 26px;
}
.IconBtn_mini {
  // line-height: 18px;
  padding: 5px;
}
.icon_large {
  font-size: 1.8em;
}
.icon_medium {
  font-size: 1.5em;
}
.icon_small {
  font-size: 1.3em;
}
.icon_mini {
  font-size: 1em;
}

.IconBtn:hover {
  color: $text;
  border-radius: 5px;
  background-color: $dark;
}

.loading-true {
  animation: loading 1s linear infinite;
}

@keyframes loading {
 0% {
 transform: rotate(0deg);
 }
 100% {
 transform: rotate(360deg);
 }
}
</style>