<template>
  <div class="Page">
    <div class="gumu">
      <div class="gumu-left">
        <div class="title">
          微信公众号、小程序定制
        </div>
        <div class="sub">
          ·通用型  |  简单实用效果好<br/>
          ·定制型  |  个性品牌传播快
        </div>
        <router-link style="color:#fff;" to="/pub/apply">
          <el-button type="primary" style="width: 6em;" class="btn">开始使用</el-button>
        </router-link>       
      </div>
      <div class="gumu-right">
        <el-carousel :interval="4000" @change="onCarouselChange" class="carousel" height="45vh">
          <el-carousel-item v-for="item in slides" :key="item.img">           
            <img :src="item.imgurl">
          </el-carousel-item>
        </el-carousel>
        <div class="right-content">
          <div class="carousel-title">{{curSlide.title}}</div>
          <div class="carousel-sub">{{curSlide.sub}}</div>
        </div>
      </div>
    </div>
    <!-- <div class="gongneng">
      <el-row>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="title">商城型小程序 一键开店经营</div>
            <ul class="subs">
              <li>自带多种营销玩法，提升成交量与品牌形象</li>
              <li>会员系统，助力强粉丝裂变、提高复购率</li>
              <li>定制商城小程序+商城</li>
            </ul>
            <router-link style="color:#fff;" to="/pub/apply">
              <el-button type="primary" style="width: 6em;" class="btn">开始使用</el-button>
            </router-link>
          </div>
          </el-col>
        <el-col :span="4">
          <div class="sel-line">
            <div class="sel-item"
              v-for="(ob, idx) in selList"
              :key="idx"
              @mouseenter="onSelMouseEnter(idx)"
              >
              {{ob.label}}
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="sel-img">
            
            <img :src="selCurrent.imgurl" />
          </div>
        </el-col>
      </el-row>
    </div> -->
    <!-- <div class="moban">
      <div class="title">海量小程序模板<span class="sub">任你挑选、更快、更全面</span></div>
      <div class="tabs">
        <div @click="mobanCurrentIndex = idx" :class="'tab tab_cur_' + (idx == mobanCurrentIndex) " v-for="(item, idx) in mobanList" :key="idx">
          {{item}}
        </div>
      </div>
      <div class="imgs">
        <div class="img-item" v-for="idx in [0,1,2,3,4,5,6,7]" :key="idx">          
          <img :src="moimgs[mobanCurrentIndex][idx]">
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import IconBtn from '../components/btns/IconBtn.vue';
import IconTextBtn from '../components/btns/IconTextBtn.vue';
export default {
  components: { IconTextBtn, IconBtn },
  // name: 'Login',
  data() {
    return {
      login: false,
      curSlide: {},
      slides: [
        {img: 'bar_01.jpg', title: '快捷小程序托管', sub: '一键托管，高枕无忧'},
        {img: 'bar_02.jpg', title: '微信公众号开发', sub: '高效便捷，安全可靠'},
        {img: 'bar_03.jpg', title: 'O2O门店量身定制', sub: '个性门店，专属业务'}
      ],
      selList: [
        {label: '通用商城', img: 'sel_01.png'},
        {label: '食杂/日用', img: 'sel_02.png'},
        {label: '美容/护肤', img: 'sel_03.png'},
        {label: '服饰/箱包', img: 'sel_04.png'},
        {label: '生鲜/食品', img: 'sel_05.png'},
        {label: '数码/手机', img: 'sel_06.png'},
        {label: '酒店/住宿', img: 'sel_07.png'},
      ],
      selCurrent: {},
      mobanList: [
        '门店展示', '预约服务', '教育考试', '房产中介'
      ],
      moimgs: [],
      mobanCurrentIndex: 0,
    };
  },
  created (opt) {
    this.login = this.global.login;
    this.curSlide = this.slides[0];
    this.selCurrent = this.selList[0]
    this.slides.forEach(e=>{
      e.imgurl = require(`../assets/img/home/${e.img}`);
    })
    this.selList.forEach(e=>{
      e.imgurl = require(`../assets/img/home/${e.img}`);
    })    
    // for(var i=0; i<4; i++) {
    //   this.moimgs.push([])
    //   for(var j=1; j<9; j++) {
    //     var name = 'mo' + i + j + '.jpg';
    //     var url = require(`../assets/img/home/${name}`)
    //     this.moimgs[i].push(url);
    //   }
    // }
    // console.log(this.moimgs)
  },
  mounted(){
    // this.loading.show();
    this.checkLogin();
  },
  methods: {
    onSelMouseEnter(idx) {
      this.selCurrent = this.selList[idx]
    },
    onCarouselChange(e) {
      this.curSlide = this.slides[e];

    },
    checkLogin() {
      if (!this.global.token) return;
      var pms = {
        token: this.global.token
      }
      this.post('/users/current', pms, res=>{
        if (res.code != 0 || !res.data) {
          //token是过期的
          this.$emit('login_stat_event', {flag:false});
        } else {
          this.$router.push('/me/myhome')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

.Page {
  margin-top: 0px;

  .gumu {
    width: 100vw;
    height: 60vh;
    border-top: 1px solid #fff;
    display: flex;

    .gumu-left {
      height: 55vh;
      width: 50vw;
      background-color: #225588;

      .title{
        color: #fff;
        margin-top: 13vh;
        font-size: 3em;
        font-weight: 700;
      }

      .sub {
        color: #fff;
        margin-top: 20px;
        font-size: 1.5em;
      }



    }

    .gumu-right{
      height: 55vh;
      width: 50vw;
      background-color: #3374b4;
      border-top: 1px solid #fff;

      .carousel {
        height: 45vh;
        width: 50vw;
        margin-top: -1px;
        background-color: #5b9ada;
      }
      .right-content{

          .carousel-title{
            color: #fff;
            margin-top: 20px;
            font-size: 2.0em;
            font-weight: 700;
            text-indent: -3em;
          }

          .carousel-sub{
            margin-top: 10px;
            color: #fff;
            font-size: 1.5em;
            text-indent: 5em;
          }
        }
    }
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 360px;
    margin: 0;

  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
}

.gongneng{
  height: 55vh;
  // border: 1px solid red;
  width: 1200px;
  margin: auto;
  margin-top: -10px;

  .title {
    margin-top: 3em;
    font-size: 2em;
    color: #2e82e3;
  }
  .subs {
    font-size: 1.5em;
    color: #2e82e3;
  }
  .subs li{
    margin-left: 20%;
    list-style: square;
    text-align: left;
  }

  .sel-line {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #2e82e3;
    height: 50vh;

    .sel-item {
      text-align: center;
      font-size: 1.5em;
      cursor: pointer;
      height: 100%;
      background: #ebebeb;
      border: 1px solid #fff;
      vertical-align: middle;
      align-self: center;
      width: 100%;
      line-height: 6.5vh;
    }
    .sel-item:hover {
      background-color: #2e82e3;
      color: #fff;
    }
  }
  .sel-img img{
    max-height: 50vh;
  }
}
.moban{
  width: 1200px;
  margin: 10px auto;
  // border: 1px solid red;
  text-align: center;

  .title {
    color: #2e82e3;
    font-size: 2em;

    .sub {
    font-size: 0.6em;
    color: #333;
  }
  }

  .tabs {
    display: flex;
    text-align: center;
    margin: auto;
    justify-content: center;

    .tab {
      font-size: 1.5em;
      margin: 5px 10px;
      padding: 2px 10px;
      cursor: pointer;
    }
    .tab_cur_true{
      border-bottom: 5px solid red;
    }
    .tab:hover {
      background-color: #5b9fec;
      color: #fff;
    }
  }
  .imgs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .img-item {
      margin-top: 30px;
      box-shadow: 0 0 5px #2e82e3;
    }

  }
}
.btn{
    margin-top: 5em;
    font-size: 1.5em;
  }

  .ripple {
    --gradient: linear-gradient(to bottom right, #5794d1, #306aa5);
    background: var(--gradient);
  }
  .ripple.animating {
    background: paint(ripple), var(--gradient);
  }


</style>
