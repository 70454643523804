
function moneyFen(val) {
  return money(val / 100);
}

function money(val) {
  var ret = "0.00";
  var str = (val).toFixed(2) + '';
  var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
  var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
   ret = intSum + dot;
  return ret;
}

function isNumber(obj) {
  return obj === +obj
}

function formatDay(dt) {
  return formatDate(dt, 'yyyy-MM-dd');
}

function formatDate(dt, fmt) {
  let date = dt;
  if (typeof date == 'string') {
    // return date;
    date = new Date(dt);
  } else if (dt.__proto__  === Array.prototype) {
    
  }
  
  if (!fmt) fmt = "yyyy-MM-dd hh:mm:ss";

  if (!date || date == null) return null;
  var o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    'S': date.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  }
  return fmt
}

function monthFirst(date){
  date.setDate(1);
  return date;
}
// 获取当前月的最后一天
function monthLast(date){
  var currentMonth=date.getMonth();
  var nextMonth=++currentMonth;
  var nextMonthFirstDay=new Date(date.getFullYear(),nextMonth,1);
  var oneDay=1000*60*60*24;
  return new Date(nextMonthFirstDay-oneDay);
}

/**
 *
 * @param {*} dt 如：2020-02-18T13:04:00.710Z类似的日期
 */
const strToDate = function(dt) {
  let res = null;
  if (typeof(dt) == 'string') {
    let str = dt.replace(/-/g, "/").replace('Z', '').replace('T', ' ');
    res = new Date(str);
  } else if (typeof(dt) == 'date') {
    res = dt;
  }
  return res;
}

function isEmpty(obj) {
  if (obj === "undefined" || obj == null || obj == "") {
    return true;
  } else {
    return false;
  }
}


/**
 * @param {Object} date
 * @return {String} 如:今天 13:25, 昨天 15:23, 14日 16:11, 03月12日 12:12
 */
const shortTime = function(date) {
  // console.log(date);
  if (date == null || date == undefined || date == '') {
    return '';
  }
  let dt = date;
  if (typeof(dt) == 'string') {
    let str = date.replace(/-/g, "/");
    dt = new Date(str);
  }
  // console.log(dt);
  let res = '';
  let now = new Date();
  if (dt.getYear() != now.getYear()) {
    if ((now.getYear() - dt.getYear()) == 1) {
      res = formatDate(date,"去年MM月dd日");
    } else if((now.getYear() - dt.getYear()) == 2) {
      res = formatDate(date,"前年MM月dd日");
    } else {
      res = formatDate(date,"yy年MM月dd日");
    }
  } else if (typeof(dt.getMonth)=="function" && dt.getMonth() != now.getMonth()) {
    if (now.getMonth()-dt.getMonth()==1) {
      res = formatDate(date,"上月dd日");
    } else {
      res = formatDate(date,"MM月dd日");
    }
  } else if(dt.getDate()!=now.getDate()) {
    if (dt.getDate() + 1 == now.getDate()) {
      res = formatDate(date, "昨天 hh:mm");
    } else if (dt.getDate() + 2 == now.getDate()) {
      res = formatDate(date, "前天 hh:mm");
    } else {
      res = formatDate(date, "MM月dd日");
    }
  } else if (dt.getDate() == now.getDate()) {
    if (dt.getHours() == now.getHours()) {
      if (now.getMinutes() - dt.getMinutes() < 2) {
        res = '刚刚'
      } else {
        res = (now.getMinutes() - dt.getMinutes()) + '分钟前'
      }
    } else {
      res = formatDate(date, "hh:mm");
    }
  } else {
    res = formatDate(date, "MM月dd日 hh:mm");
  }
  return res;
}

const strim = function(str, pe) {
  let res  = str.trim();
  while (res.indexOf(pe) == 0) {
    res = res.substring(pe.length);
  }
  while (res.lastIndexOf(pe) == res.length+1) {
    res = res.substring(0, res.length-pe.length)
  }
  return res;
}

const startWith = function(str, pe) {
  return res.indexOf(pe) == 0;
}

const endWith = function(str, pe) {
  return res.lastIndexOf(pe) == 0;
}

/**
 * 在dataList中查找位置
 * @param {*} list
 * @param {*} obj 可以是对像，也可以是值
 * @param {*} key
 */
const indexOf = function(list, obj, key='id') {
  let res = -1;
  if (!list) {
    return res;
  }
  for(let n=0; n<list.length; n++) {
    let tem = list[n];
    if (obj == tem[key] || obj[key] == tem[key] || obj === tem[key]) {
      res = n;
      break;
    }
  }
  return res;
}

const find = function(list, obj, key='id') {
  let res = null;
  if (!list) {
    return res;
  }
  for(let n=0; n<list.length; n++) {
    let tem = list[n];
    if (obj == tem[key] || obj[key] == tem[key]) {
      res = tem;
      break;
    }
  }
  return res;
}

const maxDeep = 10;
const clone = function(obj, deep=0) {
  deep ++;
  if (deep > maxDeep) {
    // console.log('超过了：' + deep);
    return null;
  }
  var newobj = (typeof obj == 'array') ? [...obj] : {...obj};
  if (typeof obj !== "object") {
    return obj;
  } else {
    Reflect.ownKeys(newobj).forEach(i => {
      newobj[i] = typeof obj[i] === "object" ? clone(obj[i], deep) : obj[i];
    });
  }
  return newobj;
}

/**
 *
 * // 8 character ID (base=2)
* uuid(8, 2) // "01001010"
* // 8 character ID (base=10)
* uuid(8, 10) // "47473046"
* // 8 character ID (base=16)
* uuid(8, 16) // "098F4D35"} len
 * @param {*} radix
 */
function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [], i;
  radix = radix || chars.length;

  if (len) {
    // Compact form
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
  } else {
    // rfc4122, version 4 form
    var r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';

    // Fill in random data. At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random()*16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }

  return uuid.join('');
}

const findTreeNode = function(list, row, key='id') {
  var res = null;
  for(var n=0; n<list.length; n++) {
    var ob = list[n]
    if (ob[key] === row || ob[key] == row[key]) {
      res = ob
      return res
    }
    var tmp = findTreeNode(ob.children, row, key)
    if (tmp) {
      return tmp
    }
  }
  return res;
}

const makeTree = function(list, idkey='id', upkey='upid') {
  if (!list) {
    return [];
  }
  let map = {};
  let res = [];
  for(let n=0; n<list.length; n++) {
    let item = list[n];
    let id = item[idkey];
    let upid = item[upkey] == undefined || item[upkey] == null ? 0:item[upkey];
    item.children = [];
    if (upid > 0) {
      let up = map[upid];
      if (up != null && up != undefined) {
        item.up = up;
        up.children.push(item);
        // up.hasChildren = true
      } else {
        //无根的错误节点，丢弃
      }
    } else {
      //根
      item.up = null;
      res.push(item);
    }
    map[id] = item;
  }
  return res;
}

const html2Text = function(html) {
  var con = html.replace(/\s*/g, "");  //去掉空格
  var res =con.replace(/<[^>]+>/g, ""); //去掉所有的html标记
  var res1 = res.replace(/↵/g, "");     //去掉所有的↵符号
  var res2 = res1.replace(/[\r\n]/g, "")
  return res2;
}

/**
 * 调用： copyFrom(obj, ['id=>roomid', 'addr'])
 * @param {*} obj
 * @param {*} to
 * @param {*} fields
 */
const copyFrom = function(obj, fields) {
  let res = {};
  if (isEmpty(fields)) {
    for(let key in obj) {
      res[key] = obj[key];
    }
  } else {
    if (typeof fields == 'string') {
      fields = fields.split(',');
    }
    for(let n=0; n<fields.length; n++) {
      let key = fields[n].trim();
      let tokey = key;
      let tems = key.split('=>');
      if (tems.length > 1) {
        key = tems[0];
        tokey = tems[1];
      }
      res[tokey] = obj[key];
    }
  }
  return res;
}

const copyTo = function(obj, to, fields) {
  if (isEmpty(fields)) {
    for(let key in obj) {
      to[key] = obj[key];
    }
  } else {
    for(let n=0; n<fields.length; n++) {
      let key = fields[n];
      let tokey = key;
      let tems = key.split('=>');
      if (tems.length > 1) {
        key = tems[0];
        tokey = tems[1];
      }
      to[tokey] = obj[key];
    }
  }
  return to;
}

/**
 * 将对像的参数转为form型的参数
 * @param {} obj
 */
const toQueryParamString = function (obj, sort=false) {
  let str = '';
  var keys = Object.keys(obj);
  if (sort) {
    keys = keys.sort()
  }
  let key, val;
  for (let i = 0; i < keys.length; i++) {
    if (i > 0) {
      str += '&'
    }
    key = keys[i];
    val = obj[key];
    str += key + '=' + val;
  }
  return str;
}

const tokenString = function(str, pre, suf) {
  let res = [];
  let pos = str.indexOf(pre);
  let nextStart = 0;
  while(pos >= 0) {
    let p2 = str.indexOf(suf, pos);
    if (p2 < 0) {
      break;
    }
    let s1 = str.substring(pos + pre.length, p2);
    res.push({
      text: s1,
      start: pos,
      end: p2 + suf.length,
      src: pre + s1 + suf
    });
    nextStart = p2 + suf.length;
    pos = str.indexOf(pre, nextStart);
  }
  return res;
}

const readFile = (file) => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = ev => {
      resolve(ev.target.result)
    }
  })
}

const checkPhone = function (phone){
  if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))){
      return false
  }
  return true
}

export default {
  isEmpty,
  formatDate,
  formatDay,
  strim,
  startWith,
  endWith,
  shortTime,
  strToDate,
  clone,
  copyTo,
  copyFrom,
  checkPhone,
  indexOf,
  find,
  uuid,
  makeTree,
  findTreeNode,
  monthFirst,
  monthLast,
  money,
  moneyFen, //将分转为元
  html2Text,
  readFile,
  tokenString,
  toQueryParamString,

  isNumber,
}