<template>
  <div id="Toast">
    <div v-if="show">
      <div class="note" v-html="text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Toast',
  data: ()=>({
    show: false,
    text: '提示信息',
    // smAndDown: false,
  }),
}
</script>

<style scoped>
#Toast{
  position: absolute;
  z-index: 10001;
  top: 30vh;
  /* left: 50%;
  right: 50%; */
  /* top:50%; */
  left:50%;
/* margin-top:-100px; */
  margin-left:-5em;
  min-width: 5em;
  /* max-width: 300px; */
  /* width: 200px;
  height: 60px; */
  max-height: 3em;
  overflow: hidden;
  padding: 10px;
  color: #fff;
  background-color: #000;
  border: 1px solid #fff;
  border-radius: 10px;
  opacity: 0.6;
  box-shadow: 0 0 1px 1px #fff;
}
/* @media screen and (min-width: 400px) {
  #Toast{
    width: 80vw;
    left: 10vw;
  }
}
@media screen and (min-width: 600px) {
  #Toast{
    width: 70vw;
    left: 15vw;
  }
}
@media screen and (min-width: 900px) {
   #Toast{
    width: 50vw;
    left: 25vw;
  }
}
@media screen and (min-width: 1200px) {
   #Toast{
    width: 30vw;
    left: 35vw;
  }
}
@media screen and (min-width: 1200px) {
  #Toast{
    width: 20vw;
    left: 40vw;
  }
} */
#Toast::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  opacity: 0.35;
}
.note {
  position: relative;
  text-align: center;
  margin: auto;
  margin-top: 7px;
  margin-bottom: 15px;
  color: #fff;
  font-size: 1.2em;
  /* overflow: hidden;
 text-overflow: ellipsis; */
 white-space: nowrap;
}

</style>