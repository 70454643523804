var common = {
  // title: '魔奇应用',
  client_id: 'web',
  client_secret: 'wEb@mP_2020',   
  token: '',
  encrypt_key: '_mP-2020_12-21_@',
  msgList: [],
  // pre: 'mp_', //前缀，如localstore
  pre: process.env.VUE_APP_CODE + '_',
  app_code: process.env.VUE_APP_CODE,  
}
const values = {
  prod_mp : {
    app_name: '魔奇',
    api: `https://${process.env.VUE_APP_CODE}.moqle.net/${process.env.VUE_APP_CODE}`,
    imghome: `https://file.doyme.net/${process.env.VUE_APP_CODE}2022`,      
    home: `https://${process.env.VUE_APP_CODE}.moqle.net`,
  },

  dev_mp : {
    app_name: '魔奇',
    // api: `https://${process.env.VUE_APP_CODE}.moqle.net/${process.env.VUE_APP_CODE}`,
    // api: `https://${process.env.VUE_APP_CODE}.moqle.net/h_${process.env.VUE_APP_CODE}`, 
    // api: 'http://localhost:9903/h_mp', 
    api: 'https://mp.moqle.net/h_mp', 
    imghome: `https://file.doyme.net/${process.env.VUE_APP_CODE}2022`,    
    home: 'http://localhost:8080'
  },

  prod_yx : {
    app_name: '云讯微翼',
    api: `https://${process.env.VUE_APP_CODE}.moqle.net/${process.env.VUE_APP_CODE}`,
    imghome: `https://file.doyme.net/${process.env.VUE_APP_CODE}2022`,      
    home: `https://${process.env.VUE_APP_CODE}.moqle.net`,
  },

  dev_yx : {
    app_name: '云讯微翼',
    // api: `https://${process.env.VUE_APP_CODE}.moqle.net/h_${process.env.VUE_APP_CODE}`,  
    api: 'http://localhost:9902/h_yx', 
    imghome: `https://file.doyme.net/${process.env.VUE_APP_CODE}2022`,    
    home: 'http://localhost:8080'
  },

  prod_sm : {
    app_name: '翼欢购',
    api: `http://dx.moqle.com/${process.env.VUE_APP_CODE}`,
    imghome: `https://file.doyme.net/${process.env.VUE_APP_CODE}2022`,      
    home: `http://dx.moqle.com/vm`,
  },

  dev_sm : {
    app_name: '翼欢购',
    api: `http://dx.moqle.com/h_${process.env.VUE_APP_CODE}`,  
    imghome: `https://file.doyme.net/${process.env.VUE_APP_CODE}2022`,    
    home: 'http://localhost:8080'
  }
}


export default {  
  global: Object.assign(common, values[
      (process.env.NODE_ENV === 'development' ? 'dev_'  : 'prod_') + process.env.VUE_APP_CODE
    ]
  )
}