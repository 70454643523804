<template>
  <div>
    <div  v-if="show && mask" class="mask">
      <!-- <IconBtn class="icon-btn" icon="iconsearchclose" @click="show = false;"></IconBtn> -->
    </div>
    <div v-if="show" :class="'container container_' + mask">
        <div class="loading">
          <img class="img" src="@/assets/logo1.png" />
        </div>
        <div class="text" v-if="text != ''">{{text}}</div>
      </div>
  </div>
</template>
<script>
import IconBtn from '../btns/IconBtn';
export default {
  name: "Loading",
  components:{
    IconBtn,
  },
  data() {
    return {
      mask: true,
      show: false,
      text: 'Loading...'
    }
  }
}
</script>
<style scoped>
.container {
 position: absolute;
 padding: 0.2em 1em;
 margin: auto;
 border-radius: 20px;
 top: calc(100vh/2 - 230px);
 left: calc(100vw/2 - 2.5em);
 z-index: 10;
}
.container_true {
  width: 300px;
 height: 300px;
}
.container_false{
  background-color: #007acc;
  padding: 0.2em 1em;
}

.mask {
  position: absolute;
  /* height: calc(100vh - 32px);
  width: calc(100vw - 5px);; */
  height: 100vh;
  width: 100vw;
  top: 0;
  /* top: 30px; */
  left: 0;
  z-index: 9;
  background-color: #000;
  -moz-opacity: 0.7;
  opacity: 0.70;
  filter: alpha(opacity=70);
}

.icon-btn {
  position: absolute;
  bottom: 30px;
  right: 10px;
}

.loading {
 width: 70px;
 height: 70px;
 border-radius: 100%;
 border: 5px #ffffff solid;
 border-right-color: #87CEEB;
 animation: loading 1s linear infinite;
 text-align: center;
}
 .img {
   width: 62px;
   height: 62px;
   padding: 4px;
   border-radius: 100%;
   margin: auto;
 }


.text {
  margin-top: 5px;
  color: #fff;
 }

@keyframes loading {
 0% {
 transform: rotate(0deg);
 }
 100% {
 transform: rotate(360deg);
 }
}
</style>