// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
// import VueI18n from 'vue-i18n'
import 'lib-flexible'
import './icons' // icon
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'babel-polyfill';

import eventBus from '@/utils/bus.js'

import './assets/font/iconfont.css'
import '@/assets/css/style.scss'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'default-passive-events' //解决element form 验证时的handler as 'passive' to make the page more responsive 问题

//用失败了
// import dbclick from './utils/dbclick.js'
// Vue.directive('click', dbclick)

//在要防重复点的按钮加 v-click
Vue.directive('click', {
  inserted(el, binding) {
    el.addEventListener('click', e => {      
      if (!el.disabled) {
        el.disabled = true
        el.style.cursor = 'not-allowed'
        
        setTimeout(() => {
          el.style.cursor = 'pointer'
          el.disabled = false
        }, 1500)
        
      }
    })
  }
})

//水波
import Ripple from 'vue-ripple-directive'
Vue.directive('ripple', Ripple);
Ripple.color = 'rgba(0, 0, 0, 0.2)';

Array.prototype.remove = function(val) {
  var index = this.indexOf(val);
  if (index > -1) {
  this.splice(index, 1);
  }
};

import dialogs from './components/dialogs';
import loading from './components/Loading';

const hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
const viewportUnitsBuggyfill = require('viewport-units-buggyfill');
viewportUnitsBuggyfill.init({
    hacks,
});

Vue.config.productionTip = false

Vue.use(dialogs);
Vue.use(loading);

import '@/style.css'
import util from './utils'

import gb from '@/utils/global.js'
Vue.prototype.global = gb.global

Vue.use(ElementUI)

import '@/utils/directives'; // 弹窗拖拽


// ElementLocale.i18n((key, value) => i18n.t(key, value))

//定义一些信手就要用的方法
Vue.prototype.isEmpty = function (obj){
  if(obj == undefined || obj == "undefined" || obj == null || obj == "null" || obj == ""){
	  return true;
  }else{
	  return false;
  }
}
// import axios from 'axios'
// axios.defaults.withCredentials = true //不然取不到session
import http from './utils/http'
Vue.prototype.http = http;

//主要用这个，方便
Vue.prototype.post = function(url, obj, success, auth=true, contentType='application/json;charset=UTF-8') {  
  NProgress.start();
  http.post(url, obj, res=>{
    NProgress.done(true);
    // this.loading.hide();
    success(res);
  }, auth, contentType);
}

Vue.prototype.util = util

Vue.prototype.getItem = function(key) {
  var res = localStorage.getItem(key)
  if (res != null && res != undefined) {
    if (res.startsWith('{') && res.endsWith('}')) {
      res = JSON.parse(res)
    } else if (res.startsWith('[') && res.endsWith(']')) {
      res = JSON.parse(res)
    }
  }
  return res
}
Vue.prototype.setItem = function(key, val) {
  if (val instanceof Object) {
    localStorage.setItem(key, JSON.stringify(val))
  } else {
    localStorage.setItem(key, val)
  }
}
Vue.prototype.removeItem = function(key) {
  localStorage.removeItem(key)
}

Vue.prototype.process = function(flag) {
  if (flag) {
    NProgress.start();
  } else {
    NProgress.done(true);
  }
}
//全局消息
// window.eventBus = new Vue();
Vue.prototype.bus = eventBus;

/* eslint-disable no-new */
const app = new Vue({
  el: '#app',
  router,
  // util,
  // global,
  // components: { App },
  // template: '<App/>'
  render: h => h(App)

}).$mount('#app');

NProgress.configure({ easing: 'ease',
  minimum: 0.1, speed: 500, showSpinner: true, trickleSpeed: 200,
  parent: '#app'
});

Vue.prototype.nprogress = NProgress
app.$router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.meta.title) {
    document.title =  to.meta.title + '-' + gb.global.app_name
  }
  next()
})

app.$router.afterEach(() => {
  NProgress.done(true);
});
