import axios from 'axios'
import gb from './global'
import util from './index'
import './bus.js'
import eventBus from './bus.js';
const Qs = require('qs');

//全局超时5秒
axios.defaults.timeout = 10000;
function defaultTimeout() {
  if (process.env.NODE_ENV === 'development') {
    return 1000 * 60 * 5;
  } else {
    return 100000;
  }
}

function postAsync(url, obj, auth=true, contentType= 'application/json') {
  // var loading = false
  axios.defaults.timeout = defaultTimeout();
  util.loading();
  if (url.indexOf('http') != 0) {
    if (url.indexOf('/') != 0) {
      url = '/' + url;
    }
    url = gb.global.api + url;
  }
  // let start = new Date().valueOf();

  var headerObj;
  var token = gb.global.token //window.localStorage.getItem('token')
  if (util.isEmpty(token)) {
    // token = gb.global.token
    if(util.isEmpty(token)) {
      auth = false
    }
  }
  if (auth) {
    //不使用这种方式，避免token乱送给其它网站
    headerObj = {
      headers : {
        'Authorization': "Bearer " + token,
        'content-type': contentType,
        'Access-Control-Allow-Origin': '*',
        'Current-Key': gb.global.currentKey
      }
    }
  } else {
    headerObj = {
      headers : {
        'content-type': contentType, // 'application/json'
        'Current-Key': gb.global.currentKey
      }
    }
  }
  //检查参数中是否有null项
  Object.keys(obj).map(key =>{
    if (obj[key] == null) {
       delete obj[key]
    }
  })
  var state = {
    result: null,
    completed: false,
    success: false
  };
  return new Promise((resolve, reject) => {
    axios.post(url, obj, headerObj)
    .then(response=>{
      util.hideLoading();
      resolve(response.data);
    }).catch(response=>{
      util.hideLoading();
      let res = {
        code : 999,
        msg: '操作失败，请稍后重试~'
      }
      if (response) {
        res = {
          code: response.status || 9999,
          msg: response.message || 'Server error~'
        }
        if (response.status == 401) { //这是没有权限，在微信中如何控制？直接转oauth
          res.msg = '请求超时，请刷新~'
        }
        resolve(res);
      } else {
        reject(res);
      }
    })

  })
}

const post = function(url, obj, success, auth=true, contentType) {
  if (url.indexOf('http') != 0) {
    if (url.indexOf('/') != 0) {
      url = '/' + url;
    }
    url = gb.global.api + url;
  }
  let start = new Date().valueOf();

  var token = window.localStorage.getItem('token')  
  if (util.isEmpty(token)) {    
    if(util.isEmpty(token)) {
      auth = false
    }
  }
  var headerObj;
  if (auth) {
    // var token = gb.global.token;// window.localStorage.getItem('token')
    //不使用这种方式，避免token乱送给其它网站
    headerObj = {
      headers : {
        'Authorization': token,// "Bearer " + token,
        'content-type': contentType,
        'Access-Control-Allow-Origin': '*',
        'Current-Key': gb.global.currentKey
      }
    }
  } else {
    headerObj = {
      headers : {
        'Current-Key': gb.global.currentKey,
        'content-type': contentType //'application/json'
      }
    }
  }
  var loading = false
  try {
      // loading = util.loading()
    //检查参数中是否有null项
    Object.keys(obj).map(key =>{
      if (obj[key] == null) {
         delete obj[key]
      }
    })
    // alert(obj.username)
    axios.post(url,obj, headerObj).then(response=>{
      // if (loading) {
      //   loading.hide()
      // }
      var res = {}
      let rRes = response.data;
      if (typeof(rRes) == 'string' && rRes.indexOf("jsonpCallback(") >=0) {
        try {
          res = eval(rRes.substring(13,rRes.length));
        }catch(e) {
          console.log('evel 错误：' + rRes)
        }
      } else {
        res = rRes
      }
      if (res.code == 105 || res.code == 103) { //需要登录
        eventBus.$emit('need_login_event', true)
      } else {
        if (success) {
          if (res && !res.queryTime)
            res.queryTime = new Date().valueOf() - start
          callBackSuccess(success, res);
        }
      }
    }, errorRes => {
      var response = errorRes.response
      // if (loading){
      //   loading.hide()
      // }
      let res = {
        code : 999,
        msg: '操作失败，请稍后重试~'
      }
      if (response) {
        res = {
          code: response.status || 9999,
          msg: response.message || 'Server error~'
        }
        if (response.status == 401) { //这是没有权限，在微信中如何控制？直接转oauth
          res.msg = '登录超时，请刷新~'
          eventBus.$emit('need_login_event', true)
        }
      }
      if (success) {
        res.queryTime = new Date().valueOf() - start
        callBackSuccess(success, res);
      }
    });
  }catch(e) {
    // if (loading){
    //   loading.hide()
    // }
    console.log('connect to server error: ' + err)
    let res = {
      code: 99999,
      msg: 'connect to Server error~'
    }
    if (err) {        
      res.code = err.response.status
      if (err.response && err.response.data) {
        res.msg = err.response.data.error_description
      }
    }
    res.queryTime = new Date().valueOf() - start
    callBackSuccess(success, res);
  }
}

const post_oauth = function(url, obj, success) {
  if (url.indexOf('http') != 0) {
    if (url.indexOf('/') != 0) {
      url = '/' + url;
    }
    url = gb.global.api + url;
  }

  // let contentType = 'application/x-www-form-urlencoded'
  // var loading = false
  try {
      // loading = util.loading()
    //检查参数中是否有null项
    Object.keys(obj).map(key =>{
      if (obj[key] == null) {
         delete obj[key]
      }
    })
    // var data = Qs.stringify(obj)
    var auth_url = url;//+ '?' + data
   axios({
    headers : {
      'Content-Type': 'application/x-www-form-urlencoded', 
      'Access-Control-Allow-Origin': '*'
    },
    method: 'post',
    url: auth_url,
    data: Qs.stringify(obj)
    })  
    .then(response=>{
      // loading.hide()
      var res = {}
      let rRes = response.data;
      if (typeof(rRes) == 'string' && rRes.indexOf("jsonpCallback(") >=0) {
        try {
          res = eval(rRes.substring(13,rRes.length));
        }catch(e) {
          console.log('evel 错误：' + rRes)
        }
      } else {
        res = rRes
      }
      if (success) {
        callBackSuccess(success, res);
      }
    }).catch(err=>{
      // loading.hide()
      console.log('connect to server error: ' + err)
      let res = {
        code: 99999,
        msg: 'connect to Server error~'
      }
      if (err) {        
        res.code = err.response.status
        if (err.response && err.response.data) {
          res.msg = err.response.data.error_description
        }
      }
      callBackSuccess(success, res);
    })
  }catch(exp) {
    // loading.hide()
    let res = {
      code: 99999,
      msg: 'connect to Server error~'
    }
    if (success) {
      callBackSuccess(success, res);
    }
  }
}

const  callBackSuccess = (successFunc, res)=>{
  if (successFunc && typeof successFunc === "function") {
    successFunc(res);
  }
}


export default {
  post,
  post_oauth,
  postAsync,
}