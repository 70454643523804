<template>
    <el-container id="app" :class="'total-container total_'+login">
      <el-aside v-if="login" class="Aside" width="200px">
        <div class="logo-pan">
          <router-link to="/"><img class="Logo" src="./assets/logo.png" /></router-link>
        </div>
        <el-menu :unique-opened="true"
          class="el-menu"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
         :default-openeds="openedSubMenus" :default-active="tabCurrent" @select="onLeftMenuClick">
         <el-menu-item index="/me/myhome">
            <div class="side-item">
              <i class="iconfont iconfont-menu iconhome"></i>首页
            </div>
          </el-menu-item>
          <template v-for="item in menus" >
            <el-menu-item v-if="item.children.length==0" :index="item.path" :key="item.path">
              <div class="side-item">
                  <i :class="'iconfont  iconfont-menu '+item.icon"></i>{{item.name}}
                </div>
            </el-menu-item>
            <el-submenu v-else :index="item.path" :key="item.path">
              <template slot="title" >
                <div class="side-item">
                  <i :class="'iconfont  iconfont-menu '+item.icon"></i>{{item.name}}
                </div>
              </template>
              <!--子菜单-->
              <template v-for="sub in item.children">
                <el-menu-item class="sub-menu" :index="sub.path" :key="sub.path">
                  <i :class="'iconfont iconfont-menu '+sub.icon"></i>{{sub.name}}
                </el-menu-item>
              </template>
            </el-submenu>
          </template>
          <el-menu-item index="/logout">
            <div class="side-item">
              <i class="iconfont iconfont-menu iconlog-out"></i>退出
            </div>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container class="el-container">
        <el-header v-if="!login" class="Header"  height="61px">
          <div class="logo-title">
            <router-link to="/">
              <img class="Logo logo1" :src="logo" />              
            </router-link>            
          </div>
          <el-menu :default-active="tabCurrent" @select="onLeftMenuClick" class="el-menu-top" mode="horizontal"
            background-color="#1f5081"
            text-color="#fff"
            active-text-color="#ffd04b">
            <!-- <el-menu-item index="/">
              <i class="iconfont iconfont-menu">&#xe647;</i>首页
            </el-menu-item> -->
            <!-- <el-menu-item index="/pub/note"><i class="iconfont">&#xe643;</i>说明</el-menu-item> -->
            <template class="hidden-md-and-up">
              <el-menu-item class="hidden-md-and-up" index="/pub/apply"><i class="iconfont">&#xe612;</i><span>注册</span></el-menu-item>
              <el-menu-item class="hidden-md-and-up" index="/login"><i class="iconfont">&#xe920;</i><span>登录</span></el-menu-item>
            </template>
            <!-- <el-menu-item index="/pub/about"><i class="iconfont">&#xe640;</i>关于</el-menu-item> -->
          </el-menu>
          <el-menu :default-active="tabCurrent" @select="onLeftMenuClick"
            class="el-menu-top-right hidden-md-and-down"
            mode="horizontal"
            background-color="#1f5081"
            text-color="#fff"
            active-text-color="#ffd04b">
            <el-menu-item  index="/pub/apply"><i class="iconfont">&#xe612;</i><span>注册</span></el-menu-item>
            <el-menu-item index="/login"><i class="iconfont">&#xe920;</i><span>登录</span></el-menu-item>
          </el-menu>
        </el-header>
        <el-main class="el-app-main" v-if="login">
          <div class="float-top">
            <!--选择平台-->
            <el-dropdown  @command="onPlatClick">
              <span>
              <div v-if="login.plat" class="plat-bar">
                <img class="plat-item-img-bar" :src="plat.head_img" />
                <label>{{plat.nick_name}}</label>
              </div>
              <icon-btn v-else icon="icondata-platform"></icon-btn>
              </span>
              <el-dropdown-menu slot="dropdown" v-if="login && login.plats">
                <el-dropdown-item v-for="item in login.plats" :key="item.id" :command="item.id">
                  <div class="plat-item">
                    <img class="plat-item-img" v-if="item.head_img" :src="item.head_img" />
                    <template v-else>
                      <img class="plat-item-img" v-if="item.auth_type==1" src="@/assets/img/icon-mp_1.png" />
                      <img class="plat-item-img" v-if="item.auth_type==2" src="@/assets/img/icon-mp_2.png" />
                    </template>
                    <div class="plat-item-label">{{item.nick_name}}</div>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <icon-btn  tip="消息" icon="iconmsg"></icon-btn>
            <icon-btn @click="onLogoutClick" tip="退出" icon="iconlog-out"></icon-btn>
          </div>
          <el-tabs class="tab-bar" size="small" v-model="tabCurrent" type="card" closable @tab-remove="onClosePageTab" @tab-click="onTagClicked">
            <el-tab-pane v-for="item in pageTabs" :key="item.path" :label="item.title" :name="item.path">
            </el-tab-pane>
          </el-tabs>
          <router-view class="routerView_login" @login_stat_event="loginStatEvent" @change_plat="onPlatClick"></router-view>
        </el-main>
        <el-main class="main-nologin" v-if="!login">
          <router-view class="routerView_nologin" @login_stat_event="loginStatEvent"></router-view>
          <el-footer class="footer">
            @2016-2021 moqle.net 魔奇应用 <a href="https://beian.miit.gov.cn" target="_blank">闽ICP备10012003号</a>
            <!-- 闽ICP备17028247号 -->
          </el-footer>
        </el-main>
      </el-container>
      <div v-if="refreshFlag"></div>
    </el-container>
</template>

<script>
import IconBtn from './components/btns/IconBtn.vue';
// var self = null;
import ws from "./utils/wsclient";
export default {
  components: { IconBtn },
  // name: "App",
  data() {
    return {
      logo: false,
      refreshFlag: false,
      newMsgCount: 0,
      login: false,
      menus: [],
      transitionName: "slide-left", //路由切换效果
      openedSubMenus: ["/pay"],
      tabCurrent: "/",
      pageTabs: [{ title: "首页", path: "/me/myhome" }],
      selectPlat: false,
      plat: false,
      client: null, //websocket client
      msgCount: 0, //显示在“消息菜单”上的数字
    };
  },
  watch: {
    $route(to, from) {
      // this.onLeftMenuClick(to.path);
      this.tabCurrent = to.path;
      // this.refresh();
    },
  },
  created() {    
    this.bus.$on('plat_changed', this.eventPlatChanged) 
    this.logo = require(`./assets/logo-${this.global.app_code}.png`)
    document.title = this.global.app_name
    this.bus.$on("need_login_event", async (res) => {
      this.onLogoutClick();
      this.clearLoginState();
      this.refresh();      
    });
    //判断是否有登录，没有则显示登录页
    this.login = this.getItem("login");
    if (this.login) {
      // console.log(this.login)
      var body = document.getElementsByTagName("body")[0];
      body.style.cssText = body.style.cssText + "overflow-y: hidden;height:100vh;"
      // console.log(appdiv.style.cssText)
      this.global.token = "Bearer " + this.login.token;
      this.global.login = this.login;
      if (this.login.plat) {
        this.global.plat = this.login.plat;
        this.plat = this.login.plat;
      }
      this.loadMenu();
      // this.pageTabs = this.getItem("pageTabs");
      // this.tabCurrent = this.getItem("tabCurrent");
      // if (this.pageTabs == null) {
      //   return
      // }
      // if (this.pageTabs.length == 0) {
      //   this.onLeftMenuClick("/me/myhome");
      // }
      this.loadPlats();      
    }
    //  else {
    //   this.tabCurrent = "/";
    //   if (this.$route.path != "/") {
    //     this.$router.push("/");
    //   }
    // }
    // this.initWebSocket(); //需要用到消息socket
    this.bus.$on("send_menu_event", async (res) => {
      this.onLeftMenuClick(res.path);
    });
    
  },  
  mounted() {
    var isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      //在页面刷新时将vuex里的信息保存到缓存里
      window.addEventListener("pagehide", (e) => this.beforUnload(e));
    } else {
      //在页面刷新时将vuex里的信息保存到缓存里
      window.addEventListener("beforeunload", (e) => this.beforUnload(e));
    }
  },
  destroyed() {
    this.bus.$off("need_login_event");
    this.bus.$off("login_status_event");
    this.bus.$off('plat_changed')
  },
  methods: {
    refresh() {
      this.refreshFlag = !this.refreshFlag;
      this.$nextTick(()=>{
        this.refreshFlag = !this.refreshFlag;
      })
    },
    loadPlats() {
      this.post('/api/me/plats', {}, res=>{
        this.loading.hide();
        if (res.code == 0) {
          this.global.login.plats = res.data.list;
          // if (this.login.platid) {
            // this.onPlatClick(this.login.platid);
          // }
          this.bus.$emit('plats_loaded');
          // this.eventPlatChanged(this.global.plat)
          // console.log(this.global.plat)
          this.refresh();          
        } else {
          this.$notify.error('获取应用列表失败~')
        }
      })
    },
    eventPlatChanged(ev) {
      this.plat = ev;
      this.loadMenu();
      // console.log(ev);
    },
    loginStatEvent(ev) {
      // console.log(ev);
      if (ev.flag) {
        this.login = this.getItem("login");
        this.global.login = this.login;
        if (this.login.plats) {
          var plat = find(this.login.plats, this.login.platid)
          console.log(plat)
          this.plat = plat;
        }
        // this.pageTabs = [{ title: "首页", path: "/me/myhome" }];
        this.global.token = "Bearer " + this.login.token;        
        this.loadMenu();
        this.loadPlats();                
        //设置当前的平台
        // this.plat = this.util.find(this.login.plats, this.login.platid);
        this.$nextTick(()=>{
          this.plat = this.login.plat;
          this.onLeftMenuClick("/me/myhome");
        })
        
        // if (this.client && this.client.stat() == 1) {
        //   this.client.send("auth", { token: this.global.token });
        // }
        // var body = document.getElementsByTagName("body")[0];
        // body.style.cssText = body.style.cssText + "overflow-y: hidden;height:100vh;"
      } else {
        this.login = false;
        this.global.login = false;
        this.clearLoginState();
        this.$router.push("/");
      }
      setTimeout(()=>{
        this.refresh();
      }, 1000)      
    },
    async beforUnload(e) {
      console.log("浏览器准备刷新");
      this.setItem("pageTabs", this.pageTabs);
      this.setItem("tabCurrent", this.tabCurrent);
    },
    loadMenu() {
      //按当前的role获取菜单
      var role = this.login.role;
      // this.loading.show('初始化...')
      this.post('api/me/menus', {role: role}, res=>{
        // this.loading.hide();
        if (res.code != 0) {
          this.$message.error('加载系统菜单失败~' + res.msg)
          return
        }        
        this.menus = res.data;
        this.$nextTick(()=>{
          var url = window.location.href
          // console.log(url)
          var path = url.replace(this.global.home, '');
          // console.log(path)          
          if (path == '/') path = '/home'
          this.onLeftMenuClick(path)
          // this.onLeftMenuClick(this.$route.path)
        })
      })
    },
    async closePageTab(targetName) {
      let tabs = this.pageTabs;
      let activeName = this.pageCurrent;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.pageCurrent = activeName;
      this.pageTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    onLogoutClick() {
      this.loading.show();
      var that = this;
      this.post("users/logout", {}, (res) => {
        that.loading.hide();
        that.clearLoginState();
        that.loginStatEvent({falg: false})
        window.location.reload();
      });
    },
    clearLoginState() {
      this.global.token = false;
      this.removeItem("login");
      this.login = false;
      this.removeItem("tabCurrent");
      this.tabCurrent = "/";
      this.removeItem("pageTabs");
      this.pageTabs = [];
    },
    onLinkClick() {
      console.log(e);
      this.$router.push(path);
    },
    handleSelect(e) {
      console.log(e);
    },
    async onLeftMenuClick(path) {
      if (path.indexOf("/") != 0) path = '/' + path;
      var tab = this.util.find(this.pageTabs, { path: path }, "path");
      if (this.$route.path == path) {
        return;
      }
      if (path == "/logout") {
        this.onLogoutClick();
        return;
      }     
      var route = this.$router.match(path);
      if (route.meta.noTab) {
        this.tabCurrent = path;
        this.$router.push({ path: path });
        return;
      }
      // if (this.tabCurrent != route.name) {
      //更新tab
      var tab = this.util.find(this.pageTabs, { path: path }, "path");
      if (this.isEmpty(tab)) {
        tab = {
          title: route.meta.title,
          path: route.path,
        };
        if (!this.isEmpty(tab.title)) {
          this.pageTabs.push(tab);
        }
      } 
      this.tabCurrent = tab.path;
      if (this.$route.path != tab.path) {
        this.$router.push(route);
        // console.log('router push => ' + tab.path, this.pageTabs)
      }
      // }
    },
    onTagClicked(tab) {
      var path = tab.name;
      if (path == this.$route.path) return;
      var route = this.$router.match(path);
      this.$router.push({ path: path });
      // console.log(tab.name)
    },
    onClosePageTab(path) {
      let tabs = this.pageTabs;
      let activeName = this.tabCurrent;
      if (activeName === path) {
        tabs.forEach((tab, index) => {
          if (tab.path === path) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.path;
            }
          }
        });
      }

      this.tabCurrent = activeName;
      this.pageTabs = tabs.filter((tab) => tab.path !== path);
      this.$router.push(activeName);
    },
    onPlatClick(platid) {
      if (platid == this.plat.id) {
        return;
      }
      var sc = this.util.find(this.login.plats, platid);
      if (!this.isEmpty(sc)) {
        this.post("api/me/changePlat", { platid: sc.id }, (res) => {
          if (res.code != 0) {
            // this.toast('切换学校失败！<br/>' + res.msg);
            this.$alert("切换平台失败！\r\n" + res.msg, "出错了~", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
            return;
          }
          this.plat = sc;
          this.selectPlat = false;
          this.login.platid = sc.id;
          this.global.login = this.login;
          this.global.plat = this.plat;          
          this.setItem("login", this.login);
          //关闭其它页，只剩下首页
          // this.pageTabs = [];
          this.pageTabs = [{ title: "首页", path: "/me/myhome" }];
          // this.$router.push("/me/myhome");
          this.$nextTick(()=>{
            this.bus.$emit("plat_changed", this.plat); //发送全局消息，让首页去更新数据
          })          
          // this.loadMenu();
          // this.onLeftMenuClick("/me/myhome");
          this.msgCount = 0;
          if (this.client && this.client.openFlag) {
            this.client.send("auth", { token: this.global.token });
          }
        });
      }
    },
    initWebSocket() {
      var path = (this.global.api + "/ws").replace("http", "ws");
      this.client = ws.client;
      this.client.on("opened", (flag) => {
        if (this.global.token) {
          this.client.send("auth", { token: this.global.token });
        }
      });

      this.client.on("message", (msg) => {
        this.msgCount++;
        this.global.msgList.unshift(msg);
        var len = this.global.msgList.length;
        if (len > 100) {
          this.global.msgList = this.global.msgList.slice(0, 100);
        }
        console.log(msg);
      });

      this.client.init(path);
    },
    startHacking() {
      this.$notify({
        title: "It works!",
        type: "success",
        message:
          "We've laid the ground work for you. It's time for you to build something epic!",
        duration: 5000,
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Helvetica, sans-serif;
  // text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.total-container{
  width: 100vw;
  height: 100vw;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}

.total_false {
  overflow-y: auto !important;
}

.el-app-main {
  width: calc(100vw - 300px);
  height: 100vw;
  // border: 1px solid red;
}

.routerView_login{
  // width: calc(100vw - 200px);
  width: 99%;
  margin: auto;
  height: calc(100vh - 80px);
  // border: 1px solid red;
}

.routerView_nologin{
  // width: calc(100vw - 40px);
  // height: calc(100vh - 90px);
  // border: 1px solid red;
  margin: 0px;
  padding: 0px;
  width: 100vw;
}

.total_false {
  flex-direction: column;
}

.Header {
  background-color: $dark;
  width: 100vw;
  display: flex;

  .logo-title {
    position: absolute;
    left: 50px;
    top: 5px;
    text-align: right;
    z-index: 1;
    display: flex;
  }

  .el-menu-top {
    flex: 1 1 auto;
    background-color: $dark;
    text-align: right;
    margin-top: 0px;
    margin-left: 200px;
    border: none;
  }

  .el-menu-top-right {
    margin-top: 0px;
    text-align: right;
    width: 20em;
    min-width: 12em;
    background-color: $dark;
    border: none;
    // border: $border;
  }
}



.logo-pan {
  background-color: $dark;
}
.Logo {
  height: 28px;
  margin-top: 12px;
}

.breadcrumb {
  height: 40px;
  text-align: left;

  .el-icon-s-fold {
    font-size: 2em;
  }
}

.Aside {
  height: calc(100vh);
  background-color: #545c64;
  // box-shadow: 1px 1px 1px 1px #cbcbcb;
}

.el-menu {
  width: 99%;
  border: none;
}
.side-item {
  font-size: 1.1em;
  font-weight: 700;
  text-align: left;
}

.sub-menu {
  text-align: left;
}

.top-right {
  position: absolute;
  right: 20px;
  top: 0px;
  height: 60px;
  color: #fff;
  z-index: 1;
  display: flex;
}

.top-nav-link {
  color: #fff;
  margin-left: 20px;
  line-height: 60px;
}



.iconfont-menu {
  margin-right: 3px;
}

.top-schools {
  .school-item {
    display: flex;
    height: 60px;
    color: #fff;
    margin-top: 0px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;

    .school-icon {
      margin-top: 10px;
      img {
        width: 40px;
        border-radius: 45px;
      }
    }
    .school-name {
      display: inline-block;
      line-height: 60px;
      margin-left: 5px;
    }
  }
  .top-schools-dropdown {
    position: absolute;
    z-index: 9999999999;
    background-color: #1f5081;
    padding: 1px;
    border-radius: 0 0 10px 10px;
    padding-bottom: 10px;

    .school-item {
      margin-top: 5px;
      border-top: 1px solid #2f6397;
    }
  }

  .school-item:hover {
    background-color: #194067;
  }
}
#main-container {
  margin-top: -20px;
  padding-top: 10px;
  padding-left: 10px;
  margin-left: -20px;
  // height: 3px;
  margin-bottom: 15px;
  width: 110%;
  z-index: 5;
  // border: 1px solid red;
}
#nprogress .bar {
  height: 2px;
  background: green !important;
  // background: #ffd04b !important; //自定义颜色
}
.tab-bar {
  margin-left: 10px;
  margin-top: 35px;
}

.el-app-main {
  margin-top: 0px;
}

.badge-msg {
  float: right;
  margin-top: -10px;
  border: none;
  // margin-right: 40px;
}

.float-top {
  position: fixed;
  z-index: 3;
  top: 2px;
  // width: calc(100vw - 230px);
  right: 10px;
  height: 30px;
  // background-color: $dark;
  text-align: right;

  .icon-btn {
    margin-top: 2px;
    margin-right: 10px;
    font-size: 1.2em;
  }
}
.plat-item{
  display: flex;
  margin-top: 5px;
}
.plat-item-label{
  line-height: 35px;
  margin-left: 5px;
}
.plat-item-img{
  width: 30px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #cbcbcb;
}

.plat-bar {
  display: inline-block;
  // height: 32px;
  padding: 3px;
  line-height: 23px;
  cursor: pointer;
  border-radius: 5px;
}

.plat-bar:hover {
  background-color: $dark;
  color: #fff;
}

.plat-item-img-bar{
  width: 23px;
  height: 23px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 23px;
}

.plat-bar label{
  display: inline-block;
  line-height: 23px;
  height: 23px;
  cursor: pointer;
}

.main-nologin {
  margin: 0px;
  padding: 0px;
  width: 100vw;
}

.el-container {
  padding: 0px;
  margin: 0px;
  width: 100vw;
}
.footer {
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding-top: 10px;
  text-align: center;
  border-top: 1px solid #2f6397;
}

.opt-bar {
  .el-button {
    height: 32px;    
  //   padding: 0px 5px;
    margin: 0px;    
  }
  .iconfont {
    padding: 0px;
    font-size: 12px;
    margin: 0px;
  }
}
.logo1 {
  // width: 70px;
  margin-left: -1.5em;
  height: 70px;
}

.pager {
  position: fixed;  
  width: 100%;  
  background-color: #f8f8f8;
  border-top: 1px solid #cbcbcb;
  left: 200px;  
  padding-top: 2px;
  bottom: 3px;

  .pager-time {
    position: fixed;
    // bottom: 12px;
    right: 10px;
  }
}
</style>
