import Toast from './Toast'
import Comfirm from './Comfirm'

const Dialogs = {}

Dialogs.install = function (Vue) {
  Vue.toast = Vue.prototype.toast = function(msg, success) {
    const ToastConstructor = Vue.extend(Toast)
    const instance = new ToastConstructor()
    const el = document.createElement('div');
    instance.$mount(el)
    document.body.appendChild(instance.$el)
    instance.text = msg;
    instance.show = true;
    setTimeout(()=>{
      instance.show = false;
      instance.text = '';
      document.body.removeChild(instance.$el);
      if (success) {
        success();
      }
    }, 2300)
  },
  Vue.comfirm = Vue.prototype.comfirm = function(title, msg, success) {
    const DlgConstructor = Vue.extend(Comfirm)
    const instance = new DlgConstructor()
    const el = document.createElement('div');
    instance.$mount(el)
    document.body.appendChild(instance.$el)
    instance.title = title;
    instance.text = msg;
    instance.show = true;
    instance.success = res=>{
      document.body.removeChild(instance.$el);
      success(res)
    }
  }
}

export default Dialogs