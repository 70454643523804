import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'

//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
// Router.prototype.push = function push(location) {
//    return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router)

export default new Router({
  mode: 'history',  
  base: process.env.BASE_URL + '/',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: '首页', noTab: true
      }
    },
    {
      path: '/fans/list',
      name: 'FansList',
      component: () => import('../views/fans/List.vue'),
      meta: {
        title: '粉丝列表', role: 'MGR'
      }
    },
    {
      path: '/fans/blacklist',
      name: 'BlackList',
      component: () => import('../views/fans/BlackList.vue'),
      meta: {
        title: '黑名单', role: 'MGR'
      }
    },
    {
      path: '/cont/medias',
      name: 'Medias',
      component: () => import('../views/cont/Medias.vue'),
      meta: {
        title: '媒体内容', role: 'MGR'
      }
    },
    {
      path: '/cont/medias_mp',
      name: 'MediasMp',
      component: () => import('../views/cont/MediasMp.vue'),
      meta: {
        title: '公众号媒体', role: 'MGR', auth_type: 1,
      }
    },
    {
      path: '/me/platinfo',
      name: 'PlatInfo',
      component: () => import('../views/me/PlatInfo.vue'),
      meta: {
        title: '我的应用'
      }
    },
    {
      path: '/mmp/rerules',
      name: 'ReRules',
      component: () => import('../views/mmp/ReRules.vue'),
      meta: {
        title: '自动回复', auth_type: 1,
      }
    },
    {
      path: '/mmp/tplmsg',
      name: 'tplmsg',
      component: () => import('../views/mmp/TplMsg.vue'),
      meta: {
        title: '模板消息', auth_type: 1,
      }
    },
    {
      path: '/mmp/setmenu',
      name: 'SetMenu',
      component: () => import('../views/mmp/SetMenu.vue'),
      meta: {
        title: '公众号菜单', auth_type: 1, //公众号
      }
    },
    //------------------------------------------------------------小程序模板库管理
    {
      path: '/minlib/draft',
      name: 'minlib-draft',
      component: () => import('../views/minlib/draft.vue'),
      meta: {
        title: '草稿箱', 
      }
    },
    {
      path: '/minlib/tpls',
      name: 'minlib-tpls',
      component: () => import('../views/minlib/tpls.vue'),
      meta: {
        title: '普通模板库', 
      }
    },
    {
      path: '/minlib/tpls_st',
      name: 'minlib-tpls_st',
      component: () => import('../views/minlib/tpls_st.vue'),
      meta: {
        title: '标准模板库', 
      }
    },
    //------------------------------------------------------------小程序设置
    {
      path: '/wax/interfaces',
      name: 'wax-interfaces',
      component: () => import('../views/wax/interfaces.vue'),
      meta: {
        title: '接口列表', 
      }
    },
    {
      path: '/wax/info',
      name: 'wax-info',
      component: () => import('../views/wax/info.vue'),
      meta: {
        title: '基本信息', 
      }
    },
    {
      path: '/wax/ver_pub',
      name: 'wax-ver_pub',
      component: () => import('../views/wax/ver_pub.vue'),
      meta: {
        title: '版本与发布', 
      }
    },
    {
      path: '/wax/privacy',
      name: 'wax-privacy',
      component: () => import('../views/wax/privacy.vue'),
      meta: {
        title: '隐私保护指引', 
      }
    },
    //------------------------------------------------------------我的
    {
      path: '/me/myhome',
      name: 'MyHome',
      component: () => import('../views/me/MyHome.vue'),
      meta: {
        title: '我的'
      }
    },
    {
      path: '/me/msg',
      name: 'Msg',
      component: () => import('../views/me/Msg.vue'),
      meta: {
        title: '消息'
      }
    },

    {
      path: '/me/profile',
      name: 'Profile',
      component: () => import('../views/me/Profile.vue'),
      meta: {
        title: '帐号信息'
      }
    },

    {
      path: '/me/help',
      name: 'Help',
      component: () => import('../views/me/Help.vue'),
      meta: {
        title: '帮助'
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('../views/pub/Login.vue'),
      meta: {
        title: '登录', noTab: true
      }
    },
    {
      path: '/pub/apply',
      name: 'Apply',
      component: () => import('../views/pub/Apply.vue'),
      meta: {
        title: '申请开通', noTab: true
      }
    },
    {
      path: '/pub/findpwd',
      name: 'FindPwd',
      component: () => import('../views/pub/FindPwd.vue'),
      meta: {
        title: '申请开通', noTab: true
      }
    },
    {
      path: '/pub/note',
      name: 'Note',
      component: () => import('../views/pub/Note.vue'),
      meta: {
        title: '业务说明', noTab: true
      }
    },

    {
      path: '/pub/about',
      name: 'About',
      component: () => import('../views/pub/About.vue'),
      meta: {
        title: '关于', noTab: true
      }
    },
    {
      path: '/sys/module',
      name: 'Module',
      component: () => import('../views/sys/Module.vue'),
      meta: {
        title: '模块'
      }
    },
    {
      path: '/sys/roles',
      name: 'Roles',
      component: () => import('../views/sys/Roles.vue'),
      meta: {
        title: '角色'
      }
    },
    {
      path: '/sys/users',
      name: 'Users',
      component: () => import('../views/sys/Users.vue'),
      meta: {
        title: '用户'
      }
    },
    {
      path: '/sys/log',
      name: 'SysLog',
      component: () => import('../views/sys/SysLog.vue'),
      meta: {
        title: '日志sys'
      }
    },
    {
      path: '/sys/test',
      name: 'Test',
      component: () => import('../views/sys/Test.vue'),
      meta: {
        title: '测试'
      }
    }
  ]
})

