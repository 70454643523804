<template>
  <div id="IconTextBtn"
    @click="onClick"
    :class="'icon-text-btn IconBtn_'+size + ' IconBtn_' + type + ' icon-text-btn_border_' + border"
    v-ripple
    >
    <el-tooltip v-if="tip"
      effect="dark"
      :content="tip"
      :open-delay="600"
      placement="bottom">
      <span>
        <i :class="'iconfont ' + icon + ' loading-'+loading"></i>
        <span v-if="!hideLabel" class="label">{{label}}</span>
      </span>
    </el-tooltip>
    <span v-else>
      <i :class="'iconfont ' + icon + ' IconBtn'"></i>
      <span v-if="!hideLabel" class="label">{{label}}</span>
    </span>
  </div>
</template>

<script>
export default {
  name : 'IconTextBtn',
  props:{
    size: {
      type: [String],
      default(){
        return 'medium';
      },
      validator: function (value) {
        if (value) {
          // 这个值必须匹配下列字符串中的一个
          return ['large', 'medium', 'small', 'mini'].indexOf(value) !== -1
        } else {
          return true;
        }
      }
    },
    icon: {
      type: String,
      default(){
        return 'iconhome';
      }
    },
    label: {
      type: String,
      default(){
        return '按钮';
      }
    },
    tip: {
      type: String,
      default(){
        return '';
      }
    },
    type: {
      type: String,
      default: ()=>{
        return 'info';
      },
      // validator: function (value) {
      //   if (value) {
      //     return ['success', 'warning', 'error', 'text'].indexOf(value) !== -1
      //   } else {
      //     return true;
      //   }
      // }
    },
    border: {
      type: Boolean,
      default(){
        return false;
      }
    },
    hideLabel: {
      type: Boolean,
      default(){
        return false;
      }
    }
  },
  data: ()=>({
    hoverFlag: false,
  }),
  methods: {
    onClick(e) {
      e.label = this.label;
      e.tip = this.tip;
      e.icon = this.icon;
      this.$emit('click', e);
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-text-btn {
  clear: both;
  text-align: center;
  padding-left: 3px;
  padding-right: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;


  .iconfont{
    margin-top: 2px;
  }

  .label {
    // margin-top: -5px;
    padding-top: 0px;
    margin-top: 0px;
    margin-left: -2px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
.icon-text-btn_border_true {
  border: $border;
}
.icon-text-btn:hover {
  color: $text;
  border-radius:5px;
  background-color: $bg-dark;

  .label {
    color: $text;
  }
}

.Icon_text {
  color: #000;
}
.Icon_error {
  color: $error;
}
.Icon_warning {
  color: $warning;
}

.IconBtn_large{
  display: inline-block;
  height: 40;
  padding: 2px 10px;
  line-height: 36px;
  font-size: 1.2em;

  .iconfont{
    font-size: 1.2em;
  }
}
.IconBtn_medium{
  height: 32;
  padding: 1px 8px;
  line-height: 28px;
  font-size: 1em;

  .iconfont{
    font-size: 1.0em;
  }
}
.IconBtn_small {
  height: 26px;
  line-height: 26px;
  font-size: 0.9em;

  .iconfont{
    font-size: 0.9em;
  }
}
.IconBtn_mini {
  height: 21px;
  line-height: 18px;
  font-size: 0.8em;

   .iconfont{
    font-size: 0.8em;
  }
}
</style>